/* 来访者 */
#welcome-info {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    padding: 10px;
    margin-top: 5px;
    border-radius: 12px;
    background-color: var(--anzhiyu-background);
    outline: 1px solid var(--anzhiyu-card-border);
}
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 3px solid var(--anzhiyu-main);
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.ip-address {
    filter: blur(5px);
    transition: filter 0.3s ease;
}
.ip-address:hover {
    filter: blur(0);
}
.error-message {
    color: #ff6565;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error-message p,
.permission-dialog p {
    margin: 0;
}
.error-icon {
    font-size: 3rem;
}
#retry-button {
    margin: 0 5px;
    color: var(--anzhiyu-main);
    transition: transform 0.3s ease;
}
#retry-button:hover {
    transform: rotate(180deg);
}
.permission-dialog {
    text-align: center;
}
.permission-dialog button {
    margin: 10px 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--anzhiyu-main);
    color: white;
    transition: opacity 0.3s ease;
}
.permission-dialog button:hover {
    opacity: 0.8;
}

/* 倒计时日历 */
.card-countdown .item-content {
    display: flex;
}

.cd-count-left {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 0.8rem;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
}

.cd-count-left .cd-text {
    font-size: 14px;
}

.cd-count-left .cd-name {
    font-weight: bold;
    font-size: 18px;
}

.cd-count-left .cd-time {
    font-size: 30px;
    font-weight: bold;
    color: var(--anzhiyu-main);
}

.cd-count-left .cd-date {
    font-size: 12px;
    opacity: 0.6;
}

.cd-count-left::after {
    content: "";
    position: absolute;
    right: -0.8rem;
    width: 2px;
    height: 80%;
    background-color: var(--anzhiyu-main);
    opacity: 0.5;
}

.cd-count-right {
    flex: 1;
    margin-left: .8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cd-count-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
}

.cd-item-name {
    font-size: 14px;
    margin-right: 0.8rem;
    white-space: nowrap;
}

.cd-item-progress {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background-color: var(--anzhiyu-background);
    overflow: hidden;
}

.cd-progress-bar {
    height: 100%;
    border-radius: 8px;
    background-color: var(--anzhiyu-main);
}

.cd-percentage,
.cd-remaining {
    position: absolute;
    font-size: 12px;
    margin: 0 6px;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cd-many {
    color: #fff;
}

.cd-remaining {
    opacity: 0;
    transform: translateX(10px);
}

.card-countdown .item-content:hover .cd-remaining {
    transform: translateX(0);
    opacity: 1;
}

.card-countdown .item-content:hover .cd-percentage {
    transform: translateX(-10px);
    opacity: 0;
} 

/* 页脚动物 */
#footer-animal {
    position: relative;
}
#footer-animal::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 36px;
    background: url(https://lib.bsgun.cn/2024/10/23/504654.webp) repeat center / auto 100%;
    box-shadow: 0 4px 7px rgba(0,0,0,.15);
}
.animal {
    position: relative;
    max-width: min(974px, 100vw);
    margin: 0 auto;
    display: block;
}
#footer-bar {
    margin-top: 0 !important;
}
@media screen and (max-width: 1023px) {
    #footer-animal::before {
        height: 4vw;
    }
}
[data-theme=dark] #footer-animal {
    filter: brightness(.8);
}

/* 侧边栏 */
#aside-content >.card-widget.card-info,
#aside-content .card-widget#card-wechat {
    padding: 0!important;
}
#aside-content .card-widget {
    padding: 15px!important;
}

#aside-content .aside-list>.aside-list-item .thumbnail, 
#aside-content .aside-list>.aside-list-item .content {
    width: 3.2em !important;
    height: 3.2em !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media screen and (min-width: 1200px) {
    #aside-content .aside-list > .aside-list-item:hover {
        transform: scale(1)!important;
    }
    #aside-content .aside-list > .aside-list-item:active {
        transform: scale(0.97) !important;
    }
}

.share-link .share-main .share-main-all #qrcode {
    min-width: auto!important;
    min-height: auto!important;
}

/* 侧边评论 */
.card-latest-comments .item-headline i {
    color: var(--anzhiyu-main);
}

.card-latest-comments .headline-right {
    position: absolute;
    right: 24px;
    top: 16px;
    transition: all 0.3s;
    opacity: 0.6;
}

.card-latest-comments .headline-right:hover {
    color: var(--anzhiyu-main);
    opacity: 1;
    transform: rotate(90deg);
}

.aside-list-author {
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 22px;
    gap: 5px;
}

.aside-list-date {
    font-size: 0.7rem;
    font-weight: normal;
    margin-left: auto;
}

.aside-list-content {
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
    line-height: 1.2;
}

.aside-list-item:last-child {
    margin-bottom: 0!important;
}

[data-theme='dark'] .aside-list-item-right {
    filter: brightness(0.95);
}

/* 双栏布局自定义样式 */
#recent-posts > .recent-post-item > .recent-post-info {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 32px !important;
    justify-content: center !important;
    gap: 8px;
}

#content-inner #recent-posts > .recent-post-item > .recent-post-info > .content {
    opacity: 1 !important;
    height: auto !important;
    line-height: 1.5 !important;
    margin-top: 0 !important;
    font-size: 15px !important;
}

.recent-post-info-top-tips {
    display: flex;
    gap: 10px;
    position: absolute;
    z-index: 999;
    background: rgb(255 255 255 / 20%);
    padding: 0 5px;
    margin: 10px !important;
    border-radius: 6px;
    color: var(--anzhiyu-white);
    backdrop-filter: blur(5px);
}

.recent-post-info-top-tips.right {
    right: 0;
}

.recent-post-info-top-tips.left {
    left: 0;
}

[data-theme='dark'] .recent-post-info-top-tips {
    filter: brightness(0.8);
}

.article-meta-wrap {
    position: inherit !important;
    padding: 0 !important;
}

.article-meta.sticky-warp,
span.newPost {
    color: #ff7242;
}

.article-categories-original,
span.newPost {
    display: flex;
    gap: 5px;
    align-items: center;
}

span.article-meta.tags,
span.post-meta-date {
    height: 26px;
    line-height: 26px;
}

.recent-post-info .recent-post-info-top {
    padding: 0 !important;
}

.unvisited-post {
    display: none !important;
}

@media screen and (max-width: 768px) {
    #recent-posts > .recent-post-item > .recent-post-info > .article-meta-wrap {
        bottom: 0 !important;
    }
    .recent-post-info-top-tips.right {
        right: inherit;
    }
}